.button_common {
    background-color: black;
    color: white;
    padding: 15px 30px;
    border: none;
    font-size: 22px;
    border-radius: 15px;
    margin: 1rem;
    width: 90%;
}
.error {
    color: #e7595c  !important;
    font-size: 15px !important;
    padding-top: 5px !important;
}
