:root {
    --gray: #e4e4e4;
    --char-coal: #383838;
    --white: #fff;
  }
  
  .price-range {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 300px;
    height: 300px; */
  }
  
  .slider {
    cursor: pointer;
    width: 100%;
  }
  
  .track {
     background-color: #6a4141;
    height: 7px;
  }
  
  .track:nth-child(2) {
    background-color: var(--char-coal);
  }
  
  .thumb-priceRang {
        width: 0.95rem;
    height: 0.95rem;
    background: #3c3c3c;
    /* border: 2px solid #b90d0d; */
    /* border-radius: 50%; */
    cursor: pointer;
    position: absolute;
    top: -5px;
  }
  
  .values-wrapper {
    margin-top: 2rem;
  }
  
.track:nth-child(2){position: absolute;
  left: 0px;
  right: 0px;
  height: 7px;
}
.track:nth-child(2) {
  background-color: #ed3237;
}
#weight{
  font-weight: 600;
  margin-left: 5px;

}
